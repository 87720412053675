import Gallery from "./Gallery";

const PinkChristmas = () => (
  <Gallery
    imageNames={[
      "pink christmas-01.png",
      "pink christmas-02-02.png",
      "pink christmas-03.png",
    ]}
  />
);
export default PinkChristmas;
