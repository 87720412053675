import Gallery from "./Gallery";

const BeAGoodSmoker = () => (
  <Gallery
    imageNames={[
      "be a good smoker1.jpg",
      "be a good smoker2.jpg",
      "be a good smoker3.jpg",
      "be a good smoker4.jpg",
    ]}
  />
);

export default BeAGoodSmoker;
