import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate  } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const menuItems = [
  { label: 'Be a Good Smoker 🚬', path: '/be-a-good-smoker' },
  { label: "Can't Talk RN 😶", path: '/cant-talk-rn' },
  { label: 'Colourful Coral 🪸', path: '/colourful-coral' },
  { label: 'Mermaid Magic 🧜‍♀️', path: '/mermaid-magic' },
  { label: 'Pink Christmas 🦩', path: '/pink-christmas' },
  { label: 'South African Classics 🇿🇦', path: '/south-african-classics' },
  { label: 'Under the Sea 🦀', path: '/under-the-sea' },
];

const Header = ({ handleMenuItemClick }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 960);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuItemClickInternal = (path) => {
    handleDrawerClose();
    navigate(path);
    if (mobile) {
      setDrawerOpen(false);
    }
  };

  const renderMenuItems = () => (
    <div className={classes.drawer} onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
      <List className={!mobile ? classes.list : undefined}>
        {menuItems.map((item) => (
          <ListItem button key={item.label} onClick={() => handleMenuItemClickInternal(item.path)}>
            <ListItemText
                primary={item.label}></ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {mobile && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" className={classes.title}>
            sarah ekron 🔥
          </Typography>
          {!mobile && renderMenuItems()}
        </Toolbar>
      </AppBar>
      <Drawer open={drawerOpen} onClose={handleDrawerClose}>
        {renderMenuItems()}
      </Drawer>
    </div>
  );
};

export default Header;
