import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  media: {
    maxWidth: 200,
    margin: "0 auto",
    padding: "0.1em",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "5%",
    backgroundColor: `${theme.palette.secondary.main}`,
  },
  button: {
    border: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: "2rem",
  },
}));

const ImageSlider = ({ images }) => {
  const classes = useStyles();

  return (
    <Card raised className={classes.root}>
      {images.map((image, index) => (
        <CardMedia
          key={index}
          component="img"
          image={image}
          sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
        />
      ))}
    </Card>
  );
};

export default ImageSlider;
