import Gallery from "./Gallery";

const SouthAfricanClassics = () => (
  <Gallery
    imageNames={[
      "south african classics-01.png",
      "south african classics-02.png",
      "south african classics-03.png",
      "south african classics-04.png",
      "south african classics-05.png",
    ]}
  />
);
export default SouthAfricanClassics;
