import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


import Header from './Header.js';
import BeAGoodSmoker from './BeAGoodSmoker.js';
import CantTalkRN from './CantTalkRN.js';
import ColourfulCoral from './ColourfulCoral.js';
import MermaidMagic from './MermaidMagic.js';
import PinkChristmas from './PinkChristmas.js';
import SouthAfricanClassics from './SouthAfricanClassics.js';
import UnderTheSea from './UnderTheSea.js';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white'
  },
}));


const App = () => {    
  const classes = useStyles();
  const handleMenuItemClick = (index) => {
    // TODO?
  };

  return (
    <Router>
      <div className={classes.root}>
        <Header handleMenuItemClick={handleMenuItemClick} />
        <Routes>
          <Route path="/be-a-good-smoker" element={<BeAGoodSmoker />} />
          <Route path="/cant-talk-rn" element={<CantTalkRN />} />
          <Route path="/colourful-coral" element={<ColourfulCoral />} />
          <Route path="/mermaid-magic" element={<MermaidMagic />} />
          <Route path="/pink-christmas" element={<PinkChristmas />} />
          <Route path="/south-african-classics" element={<SouthAfricanClassics />} />
          <Route path="/under-the-sea" element={<UnderTheSea />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
